/* eslint-disable max-len */
import { makeStyles } from '@admitkard/ccl';
import { HeaderLabel } from '../CommonComponents';
import React, { FunctionComponent } from 'react';
import Image from 'next/image';

const akInMediaLinks = {
  ndtv: 'https://www.ndtv.com/education/going-uk-for-your-masters-heres-a-good-news-for-you',
  telegraph:
    'https://www.telegraphindia.com/edugraph/career/admitkard-ceo-piyush-bhartiya-on-what-to-focus-on-when-applying-to-foreign-universities/cid/1848844',
  businessStandard:
    'https://www.business-standard.com/article/companies/admitkard-raises-rs-10-cr-in-pre-series-a-round-from-startup-founders-121071300437_1.html',
  economicsTimes:
    'https://hr.economictimes.indiatimes.com/news/workplace-4-0/recruitment/admitkard-to-hire-100-people-in-4-months/91755672',
  financialExpress:
    'https://www.financialexpress.com/education-2/admitkard-banks-on-expansion-in-middleeast-nepal-bangladesh-and-nigeria-aims-to-grow-students-base-by-10-times/2507181/',
  yourStory: 'https://yourstory.com/2021/06/edtech-startup-admitkard-full-stack-career-guidance-admission',
  theHindu:
    'https://www.thehindu.com/education/is-higher-education-abroad-a-guarantee-for-a-bright-career/article36664364.ece',
  inc42: 'https://inc42.com/buzz/edtech-startup-admitkard-raises-1-mn-in-pre-series-a-funding/',
  entrepreneur: 'https://www.entrepreneur.com/en-in/finance/admitkard-raises-inr-10-cr-in-pre-series-a-round/377110',
  mint: 'https://www.livemint.com/companies/news/admitkard-raises-rs-10-cr-from-group-of-investors-11626192693652.html',
  timeOfIndia: 'https://timesofindia.indiatimes.com/admitkard-to-hire-100-people-in-4-months/articleshow/91748484.cms',
};

const useStyles = makeStyles(() => ({
  root: {
    width: 'inherit',
  },
  cardContainer: {
    padding: '0 1rem',
    width: 'inherit',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: '.25rem',
    '@media screen and (min-width: 768px)': {
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      gap: '.5rem',
    },
    '@media screen and (min-width: 992px)': {
      padding: '0',
      gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
    },
  },
  card: {
    borderRadius: '1.25rem',
    '& img': {
      aspectRatio: '1.03738923709',
      objectFit: 'contain',
    },
    '&:nth-child(1)': {
      background: '#777777',
    },
    '&:nth-child(2)': {
      background: '#ECECEC',
    },
    '&:nth-child(3)': {
      background: '#EFEFEF',
    },
    '&:nth-child(4)': {
      background: '#FFA9A9',
    },
    '&:nth-child(5)': {
      background: '#94B2FF',
    },
    '&:nth-child(6)': {
      background: '#A9C1FF',
    },
    '&:nth-child(7)': {
      background: '#FF6868',
    },
    '&:nth-child(8)': {
      background: '#D9D9D9',
    },
    '&:nth-child(9)': {
      background: '#FFE7E7',
    },
    '&:nth-child(10)': {
      background: '#EFEFEF',
    },
    '&:nth-child(11)': {
      background: '#FFE3BC',
    },
    '&:nth-child(12)': {
      background: '#FEE4C9',
    },
  },
}));

const AdmitKardInMedia: FunctionComponent<any> = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <HeaderLabel label="admitKard in media" />
      <div id="admitkard-in-media" className={classes.cardContainer}>
        <a href={akInMediaLinks.ndtv} target="_blank" className={classes.card} rel="noreferrer noFollow">
          <Image height={180} width={180} src="https://assets.admitkard.com/images/ndtv.webp" alt="NDTV" />
        </a>
        <a href={akInMediaLinks.theHindu} target="_blank" className={classes.card} rel="noreferrer noFollow">
          <Image height={180} width={180} src="https://assets.admitkard.com/images/the_hindu.webp" alt="THE_HINDU" />
        </a>
        <a href={akInMediaLinks.entrepreneur} target="_blank" className={classes.card} rel="noreferrer noFollow">
          <Image
            height={180}
            width={180}
            src="https://assets.admitkard.com/images/entrepreneur.webp"
            alt="ENTREPRENEUR"
          />
        </a>
        <a href={akInMediaLinks.inc42} target="_blank" className={classes.card} rel="noreferrer noFollow">
          <Image height={180} width={180} src="https://assets.admitkard.com/images/inc4.webp" alt="INC42" />
        </a>
        <a href={akInMediaLinks.telegraph} target="_blank" className={classes.card} rel="noreferrer noFollow">
          <Image
            height={180}
            width={180}
            src="https://assets.admitkard.com/images/the_telegraph.webp"
            alt="THE_TELEGRAPH"
          />
        </a>
        <a href={akInMediaLinks.financialExpress} target="_blank" className={classes.card} rel="noreferrer noFollow">
          <Image
            height={180}
            width={180}
            src="https://assets.admitkard.com/images/the_financial_express.webp"
            alt="THE_FIN_EXP"
          />
        </a>
        <div className={classes.card}>
          <Image
            height={180}
            width={180}
            src="https://assets.admitkard.com/images/india_today.webp"
            alt="INDIA_TODAY"
          />
        </div>
        <a href={akInMediaLinks.timeOfIndia} target="_blank" className={classes.card} rel="noreferrer noFollow">
          <Image
            height={180}
            width={180}
            src="https://assets.admitkard.com/images/the_times_of_india.webp"
            alt="TIMEOFINDIA"
          />
        </a>
        <a href={akInMediaLinks.yourStory} target="_blank" className={classes.card} rel="noreferrer noFollow">
          <Image height={180} width={180} src="https://assets.admitkard.com/images/your_story.webp" alt="YOUR_STORY" />
        </a>
        <a href={akInMediaLinks.businessStandard} target="_blank" className={classes.card} rel="noreferrer noFollow">
          <Image
            height={180}
            width={180}
            src="https://assets.admitkard.com/images/business_standard.webp"
            alt="BUSINESS_STANDARD"
          />
        </a>
        <a href={akInMediaLinks.mint} target="_blank" className={classes.card} rel="noreferrer noFollow">
          <Image height={180} width={180} src="https://assets.admitkard.com/images/mint.webp" alt="MINT" />
        </a>
        <a href={akInMediaLinks.economicsTimes} target="_blank" className={classes.card} rel="noreferrer noFollow">
          <Image height={180} width={180} src="https://assets.admitkard.com/images/the_economic_times.webp" alt="ET" />
        </a>
      </div>
    </div>
  );
};

export default AdmitKardInMedia;
